<template>
  <b-modal
    ref="ModalCopyUrl"
    v-model="showModal"
    hide-header
    hide-footer
    centered
  >
    <div>
      <div class="title">Campaign Url</div>
      <b-input-group class="mt-2">
        <button @click="urlWebOpen()" class="btn-url">
          <b-form-input
            class="pointer"
            type="text"
            v-model="urlWeb"
            :disabled="true"
          />
        </button>
        <b-input-group-append>
          <b-button
            class="btn-copy"
            type="button"
            v-clipboard:copy="urlWeb"
            v-clipboard:success="onCopy"
            v-clipboard:error="onError"
            >Copy</b-button
          >
        </b-input-group-append>
      </b-input-group>
    </div>
    <div class="mt-3">
      <div class="title">Campaign Url (Line)</div>
      <b-input-group class="mt-2">
        <button @click="urlLineOpen()" class="btn-url">
          <b-form-input
            class="pointer"
            type="text"
            @click.prevent=""
            v-model="urlLine"
            :disabled="true"
          />
        </button>
        <b-input-group-append>
          <b-button
            class="btn-copy"
            type="button"
            v-clipboard:copy="urlLine"
            v-clipboard:success="onCopy"
            v-clipboard:error="onError"
            >Copy</b-button
          >
        </b-input-group-append>
      </b-input-group>
    </div>
  </b-modal>
</template>

<script>
export default {
  props: {
    urlLine: {
      required: true,
      type: String,
    },
    urlWeb: {
      required: true,
      type: String,
    },
  },
  data() {
    return {
      showModal: false,
      // urlLine: this.urlLine,
      // urlWeb: this.urlWeb,
    };
  },
  methods: {
    show() {
      this.showModal = true;
    },
    hide() {
      this.showModal = false;
    },
    onCopy: function (e) {
      this.successAlert(`${e.text}`, "You just copied");
    },
    onError: function (e) {
      swal("Failed to copy", `${e.text}`, "error");
    },
    urlWebOpen() {
      window.open(this.urlWeb);
    },
    urlLineOpen() {
      window.open(this.urlLine);
    },
  },
};
</script>

<style lang="scss" scoped>
.btn-copy {
  color: #fff;
  background-color: var(--primary-color);
  border-color: var(--primary-color);
}
.title {
  color: #000;
  font-weight: 600;
  font-size: 18px;
}
::v-deep .form-control {
  background-color: #f3f3f3;
  border-color: #f3f3f3;
}
.btn-url {
  border: none;
  width: 87%;
  background-color: #f3f3f3;
}
.pointer {
  cursor: pointer;
}
::v-deep .modal {
  z-index: 2000 !important;
}
</style>
